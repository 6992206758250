<template>
    <div>
        <section class="w3l-main-content" id="home">
            <div class="container">
                <div class="row align-items-center w3l-slider-grids">
                    <div class="col-lg-6 w3l-slider-left-info pe-lg-5">
                        <h1 class="title-subhny mb-2">Find Clients</h1>
                        <h3 class="mb-2 title"> <span>For</span> Your </h3>
                        <h3 class="mb-4 title">
                            <span class="typed-text"></span><span class="cursor">&nbsp</span>
                        </h3>
                        <p class="w3banr-p">
                            <b>ForumLeadsFinder</b> searches Forums and Social Media every day and notifies you of
                            clients with pain points your business could remedy.
                        </p>
                        <div class="w3l-buttons mt-sm-5 mt-4">
                            <NuxtLink to="/signup" class="btn btn-outline-primary btn-style"> Get Started For Free</NuxtLink>
                        </div>
                    </div>
                    <div class="col-lg-6 w3l-slider-right-info mt-lg-0 mt-5 ps-lg-5 align-items-center" style="min-height: 388px">
                        <div class="w3l-main-slider banner-slider">
                            <div class="owl-one owl-carousel owl-theme">
                                <div class="item">
                                    <div class="slider-info">
                                        <div class="w3l-slidehny-img banner-top1">
                                            <img src="/assets/images/banner1.webp" alt="" class="radius-image img-fluid ">
                                        </div>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="slider-info">
                                        <div class="w3l-slidehny-img banner-top2">
                                            <img src="/assets/images/banner2.webp" alt="" class="radius-image img-fluid">
                                        </div>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="slider-info">
                                        <div class="w3l-slidehny-img banner-top3">
                                            <img src="/assets/images/banner3.webp" alt="" class="radius-image img-fluid">
                                        </div>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="slider-info">
                                        <div class="w3l-slidehny-img banner-top4">
                                            <img src="/assets/images/banner4.webp" alt="" class="radius-image img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="w3l-grids-3 py-5" id="services">
            <div class="container py-md-5 py-3">
                <div class="row align-items-center">
                    <div class="col-lg-6 header-sec">
                        <h6 class="title-subhny mb-2">Marketing & Client Acquisition</h6>
                        <h3 class="title-w3l">
                            Let's grow your business together.
                        </h3>
                    </div>
                    <div class="col-lg-6 header-sec-paraw3 ps-lg-4">
                        <p class="">
                            <b>ForumLeadsFinder</b> will send you an email every day containing links to conversations
                            in Forums and Social Media where you can plug your business services.
                        </p>
                    </div>
                </div>
                <div class="row bottom_grids text-left mt-lg-5 align-items-center">
                    <div class="col-lg-4 col-md-6 mt-5">
                        <div class="grid-block">
                            <a href="#grids" class="d-block">
                                <div class="grid-block-infw3">
                                    <div class="grid-block-icon">
                                        <span class="fas fa-user-shield"></span>
                                    </div>
                                    <h4 class="my-3">Great Support.</h4>
                                </div>
                                <p class="">Your ideas and feedback are highly regarded. Someone will respond to your
                                    emails in record time.</p>
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 mt-5">
                        <div class="grid-block active">
                            <a href="#grids" class="d-block">
                                <div class="grid-block-infw3">
                                    <div class="grid-block-icon">
                                        <span class="far fa-edit"></span>
                                    </div>
                                    <h4 class="my-3">Simple to Use.</h4>
                                </div>
                                <p class=""><b>ForumLeadsFinder</b> has no hidden menus or options. Simply enter details
                                    about your business and it will handle the rest.</p>
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 mt-5">
                        <div class="grid-block">
                            <a href="#grids" class="d-block">
                                <div class="grid-block-infw3">
                                    <div class="grid-block-icon">
                                        <span class="fas fa-signal" aria-hidden="true"></span>
                                    </div>

                                    <h4 class="my-3">
                                        Increase Sales.
                                    </h4>
                                </div>
                                <p class=""><b>ForumLeadsFinder</b> analyses conversations online to ensure that only
                                    clients in need of your business services are sent your way.</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="w3l-video" id="video">
            <div class="video-mid-w3 py-5">
                <div class="container py-md-5 py-3">
                    <div class="row">
                        <div class="col-lg-6 mt-lg-0 mb-5 align-self pe-lg-3">
                            <div class="title-content text-left">
                                <h6 class="title-subhny mb-2">It is Time to grow</h6>
                                <h3 class="title-w3l two">
                                    Get new clients easily
                                </h3>
                            </div>
                            <p class="mt-md-4 mt-3">ForumLeadsFinder uses AI to filter the right customers for your
                                business and send them to you every single day.</p>
                            <div class="w3l-two-buttons">
                                <NuxtLink to="/signup" class="btn btn-style btn-primary mt-lg-5 mt-4">Get Started For
                                    Free</NuxtLink>
                                <a href="mailto:support@virock.org" class="btn btn-style btn-white mt-lg-5 mt-4 ms-2">
                                    Contact Us</a>

                            </div>
                        </div>
                        <div class="col-lg-6 videow3-right ps-lg-5">
                            <div class="history-info align-self text-center py-5 my-lg-5">
                                <video width="100%" preload="metadata" poster="/assets/images/poster.webp" controls>
                                    <source src="/assets/videos/mission.mp4" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="w3l-img-grids" id="about">
            <div class="blog py-5" id="classes">
                <div class="container py-lg-5">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-6 item mt-lg-0 mt-5">
                            <div class="w3img-grids-info">
                                <div class="w3img-grids-info-gd position-relative">
                                    <div>
                                        <div class="page">
                                            <div class="photobox photobox_triangular photobox_scale-rotated">
                                                <div class="photobox__previewbox media-placeholder">
                                                    <img class="img-fluid photobox__preview media-placeholder__media radius-image"
                                                        src="/assets/images/g1.webp" alt="">
                                                </div>
                                                <div class="photobox__info-wrapper">
                                                    <div class="photobox__info">
                                                        <span>Let AI work for you</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w3img-grids-info-gd-content mt-4">
                                        <div href="#gridsimg" class="titile-img d-block">
                                            <h4 class="mb-2">
                                                Automate Client Acquisition.
                                            </h4>
                                        </div>
                                        <p class=""><b>ForumLeadsFinder</b> uses AI to intelligently access
                                            conversations taking place online to determine the right clients for your
                                            business.</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 item mt-lg-0 mt-5">
                            <div class="w3img-grids-info">
                                <div class="w3img-grids-info-gd position-relative">
                                    <div>
                                        <div class="page">
                                            <div class="photobox photobox_triangular photobox_scale-rotated">
                                                <div class="photobox__previewbox media-placeholder">
                                                    <img class="img-fluid photobox__preview media-placeholder__media radius-image"
                                                        src="/assets/images/g2.webp" alt="">
                                                </div>
                                                <div class="photobox__info-wrapper">
                                                    <div class="photobox__info">
                                                        <span>Find new Clients daily</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w3img-grids-info-gd-content mt-4">
                                        <a href="#gridsimg" class="titile-img d-block">
                                            <h4 class="mb-2">
                                                Increase Sales Effortlessly.
                                            </h4>
                                        </a>
                                        <p class=""><b>ForumLeadsFinder</b> works every day looking for clients on your
                                            behalf. You will receive emails daily alerting you to potential clients.</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 item mt-lg-0 mt-5">
                            <div class="w3img-grids-info">
                                <div class="w3img-grids-info-gd position-relative">
                                    <div>
                                        <div class="page">
                                            <div class="photobox photobox_triangular photobox_scale-rotated">
                                                <div class="photobox__previewbox media-placeholder">
                                                    <img class="img-fluid photobox__preview media-placeholder__media radius-image"
                                                        src="/assets/images/g3.webp" alt="">
                                                </div>
                                                <div class="photobox__info-wrapper">
                                                    <div class="photobox__info">
                                                        <span>Delegate Staff to Other Tasks</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="w3img-grids-info-gd-content mt-4">
                                        <a href="#gridsimg" class="titile-img d-block">
                                            <h4 class="mb-2">
                                                Prioritize other Matters.
                                            </h4>
                                        </a>
                                        <p class="">Have <b>ForumLeadsFinder</b> focus on getting customers for your
                                            business so your team can work on more pressing matters.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup lang="ts">
import ClientGlobals from '~/clientGlobals';
import type {NuxtApp} from "#app";
import Globals from "~/global";
import Info from "../../Common/Client/Info";
import Log from "../../Common/Client/Log";
const token = useCookie("TOKEN", {maxAge: Globals.ONE_YEAR, path: "/", sameSite: "lax"});

const {
  data,
} = await useAsyncData(async function (ctx?: NuxtApp | undefined): Promise<unknown> {
  if (token.value)
    await ctx?.runWithContext(async () => await navigateTo("/dashboard", {replace: true}));
  return {};
});

useHead({
  //@ts-ignore
  title: `ForumLeadsFinder – A supernatural leads generator`,
  meta: [
    {
      name: "description",
      //@ts-ignore
      content: `ForumLeadsFinder - Do you want leads for your company sent to your inbox every day? ForumLeadsFinder will scour the internet to find the perfect clients for you`
    }
  ]
});


function addScript() {
    const scriptElement = document.createElement("script");
    scriptElement.src = "/assets/js/typing.js"
    const bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.appendChild(scriptElement);
}

onMounted(() => {
    if (ClientGlobals.started) {
        location.reload();
        return;
    }
    ClientGlobals.started = true;
    setTimeout(addScript, 100);
});

</script>

<style scoped></style>